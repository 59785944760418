import React from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Button, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";

const HamburgerButton = (props) => {
  const { handleDrawerToggle } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    handleDrawerToggle(); // Close the drawer
    if (location.pathname === path) {
      // If we're already on the page, just scroll to top
      window.scrollTo(0, 0);
    } else {
      // Otherwise, navigate to the new page
      navigate(path);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F08A53",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        gap: {
          xs: "100px",
          sm: "33px",
          md: "39px",
          lg: "150px",
        },
      }}
    >
      <IconButton
        sx={{
          position: "fixed",
          top: { xs: "20px", md: "30px", lg: "40px" },
          left: { sm: "30px", md: "30px", lg: "40px" },
        }}
        onClick={handleDrawerToggle}
      >
        <Box
          component="img"
          src="https://bandland.s3.ap-south-1.amazonaws.com/bandland_v2/remaining/common/cross.webp"
          alt="Close"
          sx={{
            width: { xs: "30px", sm: "33px", md: "39px", lg: "39px" },
            height: "auto",
            
          }}
        />
      </IconButton>

      <Grid
        container
        spacing={{ lg: "36px", xs: "22px", sm: "25px", md: "36px", xl: "38px" }}
        justifyContent="center"
      >
        {[
          { text: "HOME", path: "/" },
          { text: "STAY UPDATED", path: "/contact-us" },
          { text: "TICKETS", path: "/tickets" },
          { text: "FAQs", path: "/faq" },
        ].map((item) => (
          <Grid
            item
            xs={12}
            key={item.text}
            className="flex-center align-center"
          >
            <Button
              className="font-modesto-condensed btn-text"
              onClick={() => handleNavigation(item.path)}
              sx={{
                fontSize: {
                  xs: 52,
                  md: 64,
                  lg: 72,
                },
                padding: "0px",
                lineHeight: 0.8,
                color: "#000000 !important",
                fontWeight: "bold !important",
              }}
            >
              {item.text}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HamburgerButton;
